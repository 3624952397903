import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function MenuPrimary(props) {
  const { label, data, icon, className } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Tooltip title={label}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
          // size="small"
          // sx={{ width: "2rem", height: "2rem", backgroundColor: "#777" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className={className}
        // aria-label={label}
        >
          <Avatar sx={{ backgroundColor: "transparent" }}>{icon}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        // id="account-menu"
        open={open}
        onClose={(e: any) => {
          e.stopPropagation();
          handleClose();
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          handleClose();
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {data.map((item) =>
          item.divider ? (
            <Divider key={item} />
          ) : (
            <MenuItem
              key={item.name}
              onClick={item.onClick}
              sx={{
                height: "2.2rem",
                minWidth: "10rem",
                color: "#475569",
                fontSize: "0.8rem",

                svg: {
                  fontSize: "1.1rem",
                  color: "#475569",
                },

                ".badge": {
                  padding: "0.3rem 0.6rem",
                  backgroundColor: "#16a34a",
                  fontSize: "0.5rem",
                  marginLeft: "1rem",
                  borderRadius: "10px",
                  marginBottom: 0,
                  fontWeight: 400,
                },

                "&:hover": {
                  backgroundColor: "#0d66c2",
                  color: "#fff",

                  svg: {
                    color: "#fff",
                  },
                },
              }}
            >
              {item.icon} {item.name} {item.badge && <p className="badge">{item.badge}</p>}
            </MenuItem>
          )
        )}
      </Menu>
    </React.Fragment>
  );
}
