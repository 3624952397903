import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react';
import classes from './NavigationMenu.module.css';
import BarsIcon from '../../ui/icons/BarsIcon';
import XmarkIcon from '../../ui/icons/XmarkIcon';
import { SketShareUserModel } from "../../../helpers/models/sketShareUserModel";
import MenuPrimary from "../../ui/menu/MenuPrimary";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import * as idb from 'idb-keyval';
// import AvatarCustom from '../../AvatarCustom';
import { useIntl } from 'react-intl';


const NavigationMenu = () => {
    const router = useRouter();
    const intl = useIntl();
    const home = intl.formatMessage({ id: "navi.home"})
    const contactus = intl.formatMessage({ id: "navi.contactus"})
    const login = intl.formatMessage({ id: "navi.login"})
    const signup = intl.formatMessage({ id: "navi.signup"})
    const startsketsharing = intl.formatMessage({ id: "navi.startsketsharing"})
    let InitialState: SketShareUserModel.User;
    const [navList, setNavList] = useState("-100%");
    const [userData, setUserData] = useState(InitialState);
    const [userToken, setUserToken] = useState("");
    const [navbarActive, setnavbarActive] = useState("98%");
    const [hideUserIcon, setHideUserIcon] = useState(false)
    const UserName = userData?.first_name ? userData.first_name : userData?.name
    const UsernameFirstLetter = UserName && UserName[0].toUpperCase()

    async function handleLogout() {
        if (typeof window !== "undefined") {
            setUserData(null);
            const keys = await idb.keys()
            const filteredKeys = keys.filter((key) => key === "sketshare_userdata" || "sketshare_usertoken")
            await idb.delMany(filteredKeys).then(() => console.log("logout successfully"))
            window.localStorage.removeItem("sketshare_user");
            router.push("/");
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            const data = JSON.parse(window.localStorage.getItem("sketshare_user"));
            setUserData(data?.user);
            setUserToken(data?.token);
        }
    }, [router]);

    useEffect(() => {
        if (typeof window === "undefined") return

        const handleScroll = () => {
            if (router.pathname === "/" && window.scrollY < 480 && window.innerWidth >= 820) {
                setnavbarActive("98%");
            } else if (router.pathname === "/" && window.scrollY > 480 && window.innerWidth >= 820) {
                setnavbarActive("100%");
            } else {
                setnavbarActive("100%");
            }
        };

        const handleResize = () => {
            if (window.innerWidth < 912) {
                setHideUserIcon(false)
            } else {
                setHideUserIcon(true)
            }
        }

        if (window.innerWidth >= 820) {
            handleResize()
            handleScroll()
            setHideUserIcon(true)
        }
        else {
            setHideUserIcon(false)
        }

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);
        window.addEventListener("resize", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, [router.pathname]);

    const handlerToggleMenu = () => {
        if (navList === "-100%") {
            setNavList("0%")
        } else if (navList === "0%") {
            setNavList("-100%")
        }
    }

    return (
        <header className={classes.header} style={{ width: navbarActive }}>
            <div className={classes.container}>
                <nav className={classes.nav}>
                    <div className={classes.brand}>
                        <Link href={"/"} passHref={true}>
                            <Image src="/logo_white.svg" alt='brand logo' width={"230px"} height={"42px"} />
                        </Link>
                    </div>
                    <ul style={{ left: navList }}>
                        <li className={classes.nav_item}>
                            <Link className={classes.active} href="/" passHref={true}>
                                {home}
                            </Link>
                        </li>
                        <li className={classes.nav_item}>
                            <Link href="/contactus" passHref={true}>
                                {contactus}
                            </Link>
                        </li>
                        <li className={classes.nav_item}>
                            <span style={{margin: '0 -8px 0 0'}}><Link href={router.pathname} locale="en">English</Link></span>
                            <span style={{color: 'white', margin: '-3px 0 0 0'}}>|</span>
                            <span style={{margin: '-8px 0 0 -8px'}}><Link href={router.pathname} locale="ar">عربي</Link></span>
                        </li>                        
                        <li className={classes.nav_item}>
                            {userData ? (
                                <Link href="/canvas" passHref={true}>
                                    <button type='button' className={classes.sharingbtn}>
                                        {startsketsharing}
                                    </button>
                                </Link>
                            ) : (
                                <Link href="/login" passHref={true}>
                                    <button type='button' className={classes.sharingbtn}>
                                        {`${login} / ${signup}`}
                                    </button>
                                </Link>
                            )}
                        </li>
                        {hideUserIcon && userData && (
                            <li className={classes.nav_item}>
                                <MenuPrimary
                                    className="dashboard-menu"
                                    label={userData?.first_name ? userData.first_name : userData?.name}
                                    icon={
                                        <div className={`navbar-home-page-avatar`}>
                                            {userData && userData?.profile.includes('default-user-icon-13') ? UsernameFirstLetter : <img src={`${userData.profile}?token=${userToken}`} alt="profile" />}
                                        </div>
                                    }
                                    data={[
                                        {
                                            name: "Dashboard",
                                            icon: <DashboardOutlinedIcon sx={{ marginRight: "0.5rem" }} />,
                                            onClick: () => router.push("/dashboard"),
                                        },
                                        {
                                            name: "Log out",
                                            icon: <LogoutOutlinedIcon sx={{ marginRight: "0.5rem" }} />,
                                            onClick: handleLogout,
                                        },
                                    ]}
                                />
                            </li>
                        )}
                    </ul>
                    <div className={classes.icon}>
                        <div className={classes.small_device_ui}>
                            <span className={classes.dropdownmenu}>
                                {!hideUserIcon && userData && (
                                    <MenuPrimary
                                        className="menu-small-device-ui"
                                        label={userData?.first_name ? userData.first_name : userData?.name}
                                        icon={
                                            <div className={`navbar-home-page-avatar`}>
                                                {userData && userData?.profile.includes('default-user-icon-13') ? UsernameFirstLetter : <img src={`${userData.profile}?token=${userToken}`} alt="profile" />}
                                            </div>
                                        }
                                        data={[
                                            {
                                                name: "Dashboard",
                                                icon: <DashboardOutlinedIcon sx={{ marginRight: "0.5rem" }} />,
                                                onClick: () => router.push("/dashboard"),
                                            },
                                            {
                                                name: "Log out",
                                                icon: <LogoutOutlinedIcon sx={{ marginRight: "0.5rem" }} />,
                                                onClick: handleLogout
                                            },
                                        ]}
                                    />
                                )}
                            </span>
                            <span className={classes.menubar} onClick={handlerToggleMenu}>
                                {navList === "0%" ? (
                                    <XmarkIcon />
                                ) : (
                                    <BarsIcon />
                                )}
                            </span>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default NavigationMenu;